.business-details {
    margin: 2rem auto;
    max-width: 600px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px 5px #e6e5e5;
    border-radius: 10px;
  }
  
  .business-details h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .business-details p {
    margin-bottom: 0.5rem;
  }
  
  .business-details a {
    color: #2c3e50;
  }
  
  .edit-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .edit-buttons button {
    background-color: #2c3e50;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .edit-buttons button:hover {
    background-color: #34495e;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #2c3e50;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #34495e;
  }
  