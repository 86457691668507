.form-container {
    margin: 2rem auto;
    max-width: 600px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px 5px #e6e5e5;
    border-radius: 10px;
  }
  
  .form-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-container input[type="text"],
  .form-container input[type="number"],
  .form-container select,
  .form-container textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #f1f3ff;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-container select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232c3e50'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 9l-6 6-6-6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 1.5rem;
  }
  
  .form-container button {
    display: block;
    background-color: #2c3e50;
    color: #fff;
    border: none;
    padding: 0.75rem 1.75rem;
    margin-top: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #34495e;
  }
  
.back{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    cursor: pointer;
}

.back-arrow{
    margin-right: .5rem;
}

.back-text{
    font-weight: 500;
    font-size: 1.5rem;
}

.updating-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}
