.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.box {
    border: 1px solid #f1f3ff!important;
    border-radius: 10px;
    box-shadow: none;
    padding: 30px;
    margin-bottom: 3rem;
  }
  
  .box:hover {
    position: relative;
    z-index: 1; 
  }
  
  .box:hover::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -.1px;
    right: -.1px;
    bottom: -.1px;
    left: -.1px;
    box-shadow: 0 0 10px 5px #e6e5e5; 
    border-radius: 10px;
  }
  
.title{
    font-weight: bold;
}

.data{
    font-size: large;
    font-weight: bolder;
}

.custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .table-container {
    padding: 20px;
  }

  .header{
    display: flex ;
    justify-content: space-around;
  }

  .new-dish{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    margin: 1rem 0 1rem 0 ;
    border-radius: 10px;
    border: 1px solid rgb(201, 201, 201);
    font-weight: 600;
    cursor: pointer;
  }
  
  .new-dish:hover {
    transition: all 0.3s ease-in-out;
    background-color: rgb(204, 204, 204);
  }

  .icon-box{
    display: flex;
    justify-content: center;
  }

  .expand-icon{
    height: 1.5rem;
  }

  .expanded-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .expanded-image {
    display: flex;
    justify-content: center;
    position: relative;
    width: 50%;
    max-height: 50%;
    overflow: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .expanded-image img {
    max-width: 90%;
    height: auto;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .image-box{
    display: flex;
    justify-content: center;
  }

  .edit-dish-form {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .edit-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .edit-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    padding: 20px;
  }

  .refresh-button-area{
    display: flex;
    margin-right: 3rem;
    justify-content: flex-end;
  }
  
  .refresh-button{
    display: flex;
    justify-content: flex-end;
    margin-right: 4rem;
    padding: 10px;
    margin: 1rem 0 1rem 0 ;
    border-radius: 10px;
    border: 1px solid rgb(201, 201, 201);
    font-weight: 600;
    cursor: pointer;
    width: 6.1rem;
  }

  /* Media queries for mobile */

@media only screen and (max-width: 767px) {

  .row {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 80%;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .data {
    font-size: 1.2rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .new-dish {
    width: 80%;
  }

  .refresh-button-area {
    justify-content: center;
    margin: 1rem 0;
  }

  .refresh-button {
    width: 100%;
    justify-content: center;
    margin-right: 0;
  }

  .expanded-image {
    width: 80%;
    max-height: 80%;
  }

  .edit-form-container {
    width: 80%;
  }

}
