.form-container {
    margin: 2rem auto;
    max-width: 600px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px 5px #e6e5e5;
    border-radius: 10px;
  }
  
  .form-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-container input[type="text"],
  .form-container input[type="password"] {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #f1f3ff;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-container button {
    display: block;
    background-color: #2c3e50;
    color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #34495e;
  }

  @media only screen and (max-width: 767px) {
    .form-container {
      max-width: 400px;
      padding: 1rem;
    }
    
    .form-container input[type="text"],
    .form-container input[type="password"] {
      font-size: 0.9rem;
    }
    
    .form-container button {
      font-size: 0.9rem;
      padding: 0.4rem 1rem;
    }
  }